import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import baseUrl from '../config/baseUrl';

const httpLink = createHttpLink({
  uri: `${baseUrl}/billing_graphql`,
  fetch,
});

const link = ApolloLink.from([httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
