import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      body: {
        bg: 'var(--background-body-color)',
        color: 'var(--font-body-color)',
        fontFamily: 'Sarabun',
      },
    },
  },
  colors: {
    backgroundColor: 'var(--background-body-color)',
    primary: 'var(--primary)',
    success: 'var(--success)',
    danger: 'var(--danger)',
    'card-background': 'var(--card-background)',
  },
});

export default theme;
